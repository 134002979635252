import { mapGetters, mapActions, mapState } from 'vuex'
import { checkDate } from '@/helpers'
import { getTransactions } from '@/queries/transactions/getTransactions'
import { createReportPaymentIntent } from '@/queries/transactions/transactions.mutations'

export default {
  name: 'TTransactionsPage',
  components: {
    TFilter: () => import('@/components/filter') ,
    TStatusBar: () => import('@/components/status/bar'),
    TStatusBarItem: () => import('@/components/status/item.vue'),
    TTransactionsTable: () => import('@/components/transactions/table.vue'),
    TTransactionFilters: () => import('@/components/transactions/filters.vue')
  },

  title: ({ title }) => title,
  data () {
    return {
      title: this.$tc('Transaction', 2),
      chartOptions: {},
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0",
      total: 0,
      limit: 120,
      loadingTableData: false,
      modals: {
        modalConfirmReport: ''
      }
    }
  },
  computed: {
    ...mapState({
      filterItems: ({ transactions }) => transactions.filterItems,
      payments: ({ transactions }) => transactions.payments,
      metaDataPaymentsList: ({ transactions }) => transactions.metaDataPaymentsList,
      filters: ({ transactions }) => transactions.filters,
      paymentMethods: ({ transactions }) => transactions.paymentMethods,
      statuses: ({ transactions }) => transactions.statuses,
      filterForm: ({ transactions }) => transactions.filterForm,
      orderParams: ({ transactions }) => transactions.orderParams
    }),
    ...mapGetters({
      user: 'getUser'
    })
  },
  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('filter', [ 'toggleLoading' ]),
    ...mapActions('transactions', [ 'setPaymentsList', 'setMetaDataPaymentsList', 'addItem', 'clearFilterForm', 'clearFilterItems', 'removeItem', 'setOrderParams' ]),
    ...mapActions('notifications', [ 'toggleNotification', 'setNotificationMessage' ]),
    bindPlugins () {
      $('.tui.ui.accordion').accordion()
      this.modals.modalConfirmReport = $(this.$refs.modalConfirmReport).modal()
    },

    searchTransactions (params = {}) {
      this.toggleNotification()

      let searchParams = this.mountSearchParams()
      searchParams = {
        ...searchParams,
        ...params
      }

      this.loadingTableData = true
      getTransactions( searchParams ).then( res => {
        const { collection, metadata } = res.data.paymentIntents
        this.setPaymentsList(collection)
        this.setMetaDataPaymentsList(metadata)
        this.updateMetaDataTable()
        this.loadingTableData = false
      }).catch(e => {
        Vue.$log.error('error', e)
        this.loadingTableData = false
      })
    },

    prevPage () {
      if (this.currentPage === 1) return
      const page = Number(this.currentPage) - 1
      let params = { page }
      this.searchTransactions( params )
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return
      const page = Number(this.currentPage) + 1
      let params = { page }
      this.searchTransactions( params )
    },

    goToPage (pageNumber) {
      const page = Number(pageNumber)
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      let params = { page }
      this.searchTransactions( params )
    },

    updateMetaDataTable () {
      if ( this.metaDataPaymentsList ) {
        this.totalPages = `${this.metaDataPaymentsList.totalPages}`
        this.currentPage = `${this.metaDataPaymentsList.currentPage}`
        this.pagesSelected = `${this.metaDataPaymentsList.limitValue}`
        this.total = this.metaDataPaymentsList.totalCount
      }
    },

    applyFilter () {
      const { filterBy, equalTo, fieldOne, fieldTwo } = this.filterForm

      if ( filterBy && equalTo && fieldOne ) {

        if ( !this.filterItemAlreadySelected(filterBy) ) {
          let allowAddItem = false
          if (filterBy.key === 'period'
            && fieldOne
            && !checkDate(fieldOne)
            && fieldTwo
            && !checkDate(fieldTwo)) {
            allowAddItem = true
          } else if ( filterBy.key === 'payment_method'
            && fieldOne) {
            allowAddItem = true
          } else if ( filterBy.key === 'status'
            && fieldOne) {
            allowAddItem = true
          }

          if (allowAddItem) {
            this.toggleLoading(true)
            setTimeout(() => {
              this.addItem({ filterBy, equalTo, fieldOne, fieldTwo })
              this.searchTransactions()
              this.clearFilterForm()
              $('.tui.ui.dropdown').dropdown('clear')
              this.toggleLoading(false)
            }, 1000)
          }
        }
      }
    },

    filterItemAlreadySelected (filterBy) {
      return this.filterItems.length !== 0 && this.filterItems.find( filter => filter.filterBy.key === filterBy.key )
    },

    checkDateLocal: (date) => checkDate(date),

    mountSearchParams () {
      let params = {}
      if (this.filterItems.length > 0) {
        const paymentMethodFilter = this.filterItems.find( item => item.filterBy.key === 'payment_method' )
        const periodFilter = this.filterItems.find( item => item.filterBy.key === 'period' )
        const status = this.filterItems.find( item => item.filterBy.key === 'status' )

        if (paymentMethodFilter) {
          params.paymentMethod = paymentMethodFilter.fieldOne.key
        }

        if (status) {
          params.status = status.fieldOne.key
        }

        if (periodFilter) {
          params.createdAtBeginDate = periodFilter.fieldOne
          params.createdAtEndDate = periodFilter.fieldTwo
        }
      }

      if (this.limit) {
        params.limit = this.limit
      }

      if (this.orderParams) {
        params.orderBy = this.orderParams.orderBy
        params.order = this.orderParams.order
      }

      return params
    },

    filtersUpdated (item) {
      this.removeItem(item)
      this.searchTransactions()
    },

    orderBy (orderObject) {
      const { field, order } = orderObject
      this.setOrderParams({ orderBy: field, order })
      this.searchTransactions()
    },

    callCreateReport() {
      let searchParams = this.mountSearchParams()

      if (!searchParams.createdAtBeginDate || !searchParams.createdAtEndDate) {
        this.setNotificationMessage("É necessário informar o filtro de Período!")
        this.toggleNotification({ active: true, type: 'error' })
      } else {
        this.modals.modalConfirmReport.modal('show')
      }

    },

    generateReport() {
      let searchParams = this.mountSearchParams()

      createReportPaymentIntent(searchParams)
        .then(res => {
          const { success, errors } = res.data.createReportPaymentIntents
          if (!success && errors) {
            const { message } = errors[0]
            this.setNotificationMessage(message)
            this.toggleNotification({ active: true, type: 'error' })
          } else if (success) {
            this.setNotificationMessage("Sua solicitação foi recebida e o arquivo será enviado para o seu e-mail. Isso pode demorar alguns minutos.")
            this.toggleNotification({ active: true, type: 'processing' })
          }
        }).catch(e => {
          Vue.$log.error('error', e)
        })
    }
  },

  mounted () {
    this.clearFilterForm()
    this.bindPlugins()
    this.changeBreadcrumbItems([
      { to: { name: 'transactions' }, title: 'Transações', active: true }
    ])
    this.searchTransactions()
  },

  beforeDestroy () {
    this.modals.modalConfirmReport.remove()
  }
}
