var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("t-page-header", {
        attrs: {
          title: _vm.$tc("Transaction", 2),
          popup:
            "<div class='content'><p>Aqui você pode visualizar todas as entradas de recursos financeiros para sua ONG. Na parte de cima, você pode visualizar os valores totais dessas transações financeiras. Abaixo, há uma lista com todas as transações realizadas desde o início da operação. No menu à esquerda é possível filtrar apenas as transações que você deseja visualizar. Clicando em cada item da lista, você pode visualizar os detalhes de cada transação.</p></div>"
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass: "tui tuim ui basic button",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.callCreateReport()
                      }
                    }
                  },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: "file" }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("ExportTo", { to: ".xlsx" })) +
                        "\n      "
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "notifications",
            fn: function() {
              return [_c("t-notification")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            [
              _c("t-filter", {
                ref: "filterTransaction",
                attrs: {
                  applyFilter: _vm.applyFilter,
                  form: _vm.filterForm,
                  filterItems: _vm.filterItems,
                  filterUpdated: _vm.filtersUpdated
                },
                scopedSlots: _vm._u([
                  {
                    key: "fields",
                    fn: function() {
                      return [_c("t-transaction-filters")]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "t-page-content",
            [
              false
                ? _c(
                    "t-status-bar",
                    {
                      attrs: {
                        title:
                          "<strong>" +
                          _vm.total +
                          "</strong> " +
                          _vm.$tc("Transaction", _vm.total)
                      }
                    },
                    _vm._l(_vm.statusItems, function(item, k) {
                      return _c("t-status-bar-item", {
                        key: k,
                        attrs: { item: item }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              false
                ? _c("t-line-chart", {
                    staticClass: "tui tuim chart line",
                    attrs: {
                      chartData: _vm.chartData,
                      options: _vm.chartOptions
                    }
                  })
                : _vm._e(),
              _vm.payments.length === 0 && !_vm.loadingTableData
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhuma transação",
                      subheader: "Não há transações"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("t-transactions-table", {
                        attrs: {
                          payments: _vm.payments,
                          loading: _vm.loadingTableData
                        },
                        on: { orderBy: _vm.orderBy }
                      }),
                      _vm.totalPages > 1
                        ? _c("t-pagination", {
                            attrs: {
                              btnClickPrev: this.prevPage,
                              btnClickNext: this.nextPage,
                              totalPages: _vm.totalPages,
                              currentPage: _vm.currentPage,
                              pagesSelected: _vm.pagesSelected,
                              pages: [5, 10, 50, 100],
                              goToPage: _vm.goToPage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "modalConfirmReport",
          staticClass: "ui tiny modal confirm-report"
        },
        [
          _c("div", { staticClass: "header" }, [
            _vm._v("Confirmar exportação")
          ]),
          _c("div", { staticClass: "content" }, [
            _vm._m(0),
            _vm._m(1),
            _c("p", [
              _c("span", {
                staticClass: "user-email",
                domProps: { textContent: _vm._s(_vm.user.email) }
              })
            ])
          ]),
          _c("div", { staticClass: "actions" }, [
            _c("div", { staticClass: "ui cancel button" }, [
              _vm._v("\n        Cancelar\n      ")
            ]),
            _c(
              "div",
              {
                staticClass: "ui approve primary button",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.generateReport()
                  }
                }
              },
              [_vm._v("\n        Exportar\n      ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c("p", [_vm._v("Tem certeza que deseja exportar esse relatório?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _vm._v(
          "O processo de exportação pode demorar alguns minutos.  O arquivo será enviado\n          para o e-mail abaixo assim que concluído."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }